<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { GetAllConsultant, AddBusyTime } from "@/services/consultant.js";
import appConfig from "@/app.config";
import Table from "@/components/elements/Tables";
import moment from "moment";
import Swal from "sweetalert2";
export default {
  page: {
    title: "consultant",
    meta: [{ name: "Clients", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Table },
   created() {
    GetAllConsultant().then((data) => {
      this.tableData = data
      this.tableData.map((object) => {
        return {
          status: object.isPaid, // Rename the attribute
          ...object, // Copy all existing properties
        };
      });
    });

  },

  data() {
    return {
      tableData: [],
      title: this.$t("menuitems.consultant.text"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.consultant.text"),
        },
        {
          text: this.$t("all"),
          active: true,
        },
      ],

      filterOn: [],
      sortBy: "start",

      fields: [
        {
          label: this.$t("client"),
          key: "clientName",
          sortable: true,
        },
        { label: this.$t("title"), key: "title", sortable: true },
        {
          label: this.$t("start_date"),
          key: "start",
          sortable: true,
          formatter: (value) => {
            return moment(value).format("YYYY-MM-DD HH:mm");
          },
          filterByFormatted: true,
        },
        {
          label: this.$t("end_date"),
          key: "end",
          sortable: true,
          formatter: (value) => {
            return moment(value).format("YYYY-MM-DD HH:mm");
          },
          filterByFormatted: true,
        },
        {
          label: this.$t("duration"),
          key: "duration",
          sortable: true,
          formatter: (value) => {
            return value ? value : this.$t("busyTime");
          },
          filterByFormatted: true,
        },
        {
          label: this.$t("status"),
          key: "isPaid",
          sortable: true,
          formatter: (value, data, item) => {
            return value
              ? item.isClosed
                ? this.$t("closed")
                : this.$t("paid")
              : !item.duration? 'ــــــــ' : this.$t("not_paid");
          },
          filterByFormatted: true,
        },
      ],
      addData: { start: "", end: "", title: "" },
    };
  },
  computed: {
    FlattentableData() {
      return this.tableData.map((consultant) => {
        // Flatten the object structure and return a new object
        return {
          id: consultant.id,
          title: consultant.title,
          start: consultant.start,
          end: consultant.end,
          duration: consultant.duration,
          isPaid: consultant.isPaid,
          isClosed: consultant.isClosed,
          clientName: consultant.clientName ||'ــــــــ' ,
          clientId:consultant.clientId,
          // clientName:consultant.clientID?.data()
          // Include other properties you want to flatten
        };
      });
    },
  },
  methods: {
    rowClicked(item) {
      this.$router.push({ path: `/consultant/${item.id}` });
    },
    addBusyTime() {
      AddBusyTime(
        new Date(this.addData.start),
        new Date(this.addData.end),
        this.addData.title
      ).then(() => {
        this.successmsg();
        this.$bvModal.hide("modal-add");
      });
    },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Busy Time Has Been Added",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Table
      :tableData="FlattentableData"
      :fields="fields"
      :sortBy="sortBy"
      :filterOn="filterOn"
      @rowClicked="rowClicked"
      :addbtn="true"
      :filter-keys="['clientName','duration','isPaid']"
    />
    <b-modal
      no-close-on-backdrop
      id="modal-add"
      :title="$t('add_busy_date')"
      centered
      title-class="font-18"
    >
      <!-- disable modal default Buttons -->
      <template #modal-footer>
        <div></div>
      </template>
      <!-- modal form -->
      <form @submit.prevent="addBusyTime">
        <input
          type="text"
          :placeholder="$t('title')"
          v-model="addData.title"
          class="form-control mb-4"
          required
        />
        <label for="date-time-start">{{ $t("start_date") }}</label>
        <b-form-input
          id="date-time-start"
          v-model="addData.start"
          type="datetime-local"
          required
        ></b-form-input>
        <label for="date-time-end">{{ $t("end_date") }}</label>
        <b-form-input
          id="date-time-end"
          v-model="addData.end"
          type="datetime-local"
          required
        ></b-form-input>
        <!-- buttons for modal  -->
        <b-row
          ><b-col cols="8"></b-col>
          <b-col cols="2" style="margin-top: 10px"
            ><b-button @click="$bvModal.hide('modal-add')">{{
              $t("cancel")
            }}</b-button>
          </b-col>
          <b-col cols="2" style="margin-top: 10px">
            <b-button variant="primary" type="submit">{{ $t("ok") }}</b-button>
          </b-col></b-row
        >

        <!-- End buttons -->
      </form>
      <!-- end form -->
    </b-modal>
  </Layout>
</template>
